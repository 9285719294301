import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";

type ConsentType = "necessary" | "analytics" | "marketing";

const CookiesBanner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [consent, setConsent] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  // Check localStorage on component mount
  useEffect(() => {
    const storedConsent = localStorage.getItem("cookiesAccepted");
    if (storedConsent) {
      setShowBanner(false);
    }
  }, []);

  const handleSwitchChange = (type: ConsentType) => {
    setConsent((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleGlobalConsent = (acceptAll: boolean) => {
    setConsent({
      necessary: true,
      analytics: acceptAll,
      marketing: acceptAll,
    });
    localStorage.setItem("cookiesAccepted", "true"); // Save in localStorage
    setShowBanner(false);
  };

  const handleAccept = () => {
    setConsent({
      necessary: true,
      analytics: true,
      marketing: true,
    });
    localStorage.setItem("cookiesAccepted", "true"); // Save in localStorage
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-[800px] absolute bottom-4">
        <h2 className="text-xl font-bold mb-4">Cookie Preferences</h2>
        <p className="text-sm mb-6">
          We use cookies to enhance your experience on our website. Some are
          necessary for the website's functionality, while others help us
          analyze usage or provide personalized marketing. You can manage your
          preferences below.
        </p>
        <div className="space-y-2">
          <div className="flex justify-between sm:px-20 gap-2">
            <div className="bg-[#be95ff] px-4 py-2  w-40 flex justify-center rounded-md">
              <div>
                <p className="text-md">Necessary</p>
                <Switch checked={consent.necessary} disabled color="primary" />
              </div>
            </div>
            <div className="bg-[#be95ff] px-4 py-2  w-40 flex justify-center rounded-md">
              <div>
                <p className="text-md">Statistics</p>
                <Switch
                  checked={consent.analytics}
                  onChange={() => handleSwitchChange("analytics")}
                  color="primary"
                />
              </div>
            </div>
            <div className="bg-[#be95ff] px-4 py-2  w-40 flex justify-center rounded-md">
              <div>
                <p className="text-md">Marketing</p>
                <Switch
                  checked={consent.marketing}
                  onChange={() => handleSwitchChange("marketing")}
                  color="primary"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2 flex justify-between gap-4 sm:px-20">
            <button
            className="border-blue-800 border hover:bg-[#f4eefe] font-medium py-2 px-4 rounded w-40"
            onClick={handleAccept}
          >
            Accept all
          </button>
          <button
            className="border-blue-800 hover:bg-[#f4eefe] border font-medium py-2 px-4 rounded  w-40"
            onClick={handleAccept}
          >
            Reject all
          </button>
          <button
            className="bg-blue-800 hover:bg-[#be95ff] text-white font-medium py-2 px-4 rounded w-40"
            onClick={handleAccept}
          >
            Accept selection
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesBanner;
