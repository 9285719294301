import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import { IoMenu } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";

import logo from "../../img/wizard-logo.png";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="absolute w-[100%]">
      <div>
        <header className="bg-gradient-to-b from-black to-transparent h-[500px] bg-opacity-0">
          <nav
            className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <a
                onClick={() => navigate("/")}
                className="-m-1.5 p-1.5 cursor-pointer"
              >
                <span className="sr-only">Your Company</span>
                <img className="h-16 w-auto" src={logo} alt="" />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(true)}
              >
                <IoMenu size={40} />
                <span className="sr-only">Open main menu</span>
              </button>
            </div>
            <PopoverGroup className="hidden lg:flex lg:gap-x-12 cursor-pointer">
              <Popover className="relative">
                <a
                  onClick={() => navigate("/product")}
                  className="flex items-center gap-x-1 text-lg  leading-6 text-white"
                >
                  Product
                </a>
              </Popover>

              <a
                onClick={() => navigate("/wizardapp")}
                className="text-lg  leading-6 text-white"
              >
                App
              </a>
              <a
                onClick={() => navigate("/pricing")}
                className="text-lg  leading-6 text-white"
              >
                Pricing
              </a>
              <a
                onClick={() => navigate("/company")}
                className="text-lg  leading-6 text-white"
              >
                Company
              </a>
              <Popover className="relative">
                <PopoverButton className="flex items-center gap-x-1 text-lg  leading-6 text-white">
                  Sports
                </PopoverButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <PopoverPanel className="absolute z-50 mt-3  max-w-xs transform px-2 sm:px-0 lg:max-w-3xl">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        <a
                          onClick={() => navigate("/football")}
                          className="cursor-pointer -m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                        >
                          Football
                        </a>
                        <a
                          onClick={() => navigate("/")}
                          className="cursor-pointer -m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                        >
                          Basketball
                        </a>
                        <a
                          onClick={() => navigate("/")}
                          className="cursor-pointer -m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                        >
                          Rugby
                        </a>
                        <a
                          onClick={() => navigate("/")}
                          className="cursor-pointer -m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                        >
                          Hockey
                        </a>
                      </div>
                    </div>
                  </PopoverPanel>
                </Transition>
              </Popover>
            </PopoverGroup>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a
                onClick={() => navigate("/")}
                className="text-lg  leading-6 text-white cursor-pointer"
              >
                <button
                  onClick={() =>
                    (window.location.href = "https://wizardfootball.com")
                  }
                  className="border-2 rounded-3xl p-2 w-40 border-[#be95ff]"
                >
                  Log in <span aria-hidden="true">&rarr;</span>
                </button>
              </a>
            </div>
          </nav>
          <Dialog
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-10" />
            <DialogPanel className="fixed inset-y-0 right-0 w-full overflow-y-auto z-[9999] bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a
                  onClick={() => navigate("/")}
                  className="-m-1.5 p-1.5 cursor-pointer"
                >
                  <span className="sr-only">Your Company</span>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-white"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <IoIosClose size={40} />
                  <span className="sr-only">Close menu</span>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10 ">
                  <div className="space-y-2 py-6">
                    <Disclosure as="div" className="-mx-3">
                      <a
                        onClick={() => navigate("/product")}
                        className="cursor-pointer flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                      >
                        Product
                      </a>
                    </Disclosure>
                    <a
                      onClick={() => navigate("/wizardapp")}
                      className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                    >
                      App
                    </a>
                    <a
                      onClick={() => navigate("/pricing")}
                      className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                    >
                      Pricing
                    </a>
                    <a
                      onClick={() => navigate("/company")}
                      className="cursor-pointer -mx-3 block rounded-lg px-3 py-2 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                    >
                      Company
                    </a>
                    <Disclosure as="div" className="-mx-3">
                      <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black">
                        Sports
                      </DisclosureButton>
                      <DisclosurePanel className="space-y-1 z-50">
                        <a
                          onClick={() => navigate("/football")}
                          className="cursor-pointer block rounded-lg py-2 pl-6 pr-3 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          Football
                        </a>
                        <a
                          onClick={() => navigate("/")}
                          className="cursor-pointer block rounded-lg py-2 pl-6 pr-3 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          Basketball
                        </a>
                        <a
                          onClick={() => navigate("/")}
                          className="cursor-pointer block rounded-lg py-2 pl-6 pr-3 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                        >
                          Rugby
                        </a>
                        <a
                          onClick={() => navigate("/")}
                          className="cursor-pointer -m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                        >
                          Hockey
                        </a>
                      </DisclosurePanel>
                    </Disclosure>
                  </div>
                  <div className="py-6">
                    <a
                      onClick={() =>
                        (window.location.href = "https://wizardfootball.com")
                      }
                      className="cursor-pointer -mx-3 block rounded-lg mb-4 px-3 py-2.5 text-base  leading-7 text-white hover:bg-gray-50 hover:text-black"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>
      </div>
    </div>
  );
}
