import { IoChevronBack, IoCloseOutline } from "react-icons/io5";
import { FaHourglassStart, FaRegStar } from "react-icons/fa";
import {
  GiGoalKeeper,
  GiThrowingBall,
  GiCardDiscard,
  GiSoccerKick,
  GiCornerFlag,
  GiBurningRoundShot,
} from "react-icons/gi";
import { IoMdSwap, IoIosFootball } from "react-icons/io";
import { BiLoaderAlt } from "react-icons/bi";

import logo from "../img/wizard-logo.jpg";
import coach from "../img/coach1.png";

import { useNavigate } from "react-router-dom";
import { useRef, useState, useEffect } from "react";

import "../Styles/wizardplatform.css";

const videoUrls = [
  "https://public.wizardfootball.com/videos/wizardpreview001.mp4",
  "https://estaticos-cdn.prensaiberica.es/epi/public/videoquality/2024/0619/09/juicio-contra-cristina-segui-por-difundir-un-video-privado-de-una-de-las-victimas-de-la-violacion-gr-6.mp4", // Replace with actual URLs
  "https://media.istockphoto.com/id/1692560689/es/v%C3%ADdeo/patinaje-art%C3%ADstico-en-l%C3%ADnea-c%C3%A1mara-lenta.mp4?s=mp4-640x640-is&k=20&c=qhg4-Bfx-BQdOd4Ta-yN4rFIxhsrsZy2vX5fW82dY5E=", // Replace with actual URLs
];

const PreviewVideo = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const modalVideoContainerRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleScroll = () => {
    if (modalVideoContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        modalVideoContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setCurrentVideoIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          return nextIndex < videoUrls.length ? nextIndex : prevIndex;
        });
      }
    }
  };

  useEffect(() => {
    if (modalVideoContainerRef.current) {
      modalVideoContainerRef.current.addEventListener("touchend", handleScroll);
      return () => {
        modalVideoContainerRef.current?.removeEventListener(
          "touchend",
          handleScroll
        );
      };
    }
  }, []);
  const handlePreviousVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) =>
      prevIndex < videoUrls.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  useEffect(() => {
    setVideoLoading(true); // Reset the loading state when the video index changes
  }, [currentVideoIndex]);

  const saqueCentro = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 11; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const saquePuerta1 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 85; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const saquePuerta2 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1055; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const saqueBanda1 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 136; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const saqueBanda2 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 180; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const falta1 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 275; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const falta2 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 500; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const falta3 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 675; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const falta4 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1920; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const falta5 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 2390; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const cambio1 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 420; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const cambio2 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 945; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const cambio3 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 2000; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const corner1 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1455; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const corner2 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1670; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const tiroApuerta = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1690; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const tiroApuerta2 = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1740; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  const gol = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 2850; // 00:06 in seconds
      videoRef.current.play();
    }
  };
  return (
    <div className="bg-white w-screen h-screen">
      <div className="sm:grid grid-cols-5 p-10">
        <div className="col-span-4">
          <div className="flex items-center space-x-2 mb-4">
            <IoChevronBack
              onClick={() => navigate("/")}
              className="h-5 w-5 mt-1 cursor-pointer"
            />
            <h1 className="text-xl font-semibold ">Wizard Demo Preview</h1>
            <img
              src={logo}
              className="w-20 rounded-xl absolute z-50 mt-44 ml-4 hidden sm:block"
              alt=""
            />
          </div>

          {modal && (
            <section className="modal__bg ">
              <div className="modal__align ">
                <div>
                  <div className="modal__content ">
                    <IoCloseOutline
                      className="modal__close"
                      aria-label="Close modal"
                      onClick={closeModal}
                    />
                    <div className="bg-white h-full rounded-2xl p-20 flex justify-center items-center">
                      <p className="text-2xl font-bold">Highlights not available in Demo </p>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          )}
          <div className=" bg-black rounded relative">
            <video
              ref={videoRef}
              className="h-full w-full object-cover rounded"
              controls
            >
              <source
                src="https://public.wizardfootball.com/videos/wizardpreview002.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="pt-5 flex justify-between items-center ">
            <h1 className="text-2xl font-medium mb-4 text-[#080F42]">
              Ontinyent 1931 C.F. - U.D. Rayo Ibense | 26-04-2024 | 20:00
            </h1>
            <div>
              <button
                onClick={openModal}
                className=" h-12 bg-[#080F42] text-white px-4 py-2 rounded flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
              >
                <FaRegStar className=" hidden sm:block" />
                <span>View all Highlights </span>
              </button>
            </div>
          </div>
          <div className="hidden sm:flex justify-between items-center w-[250px] ">
            <div
              className="h-20 w-20 rounded-full bg-white border-2 border-[#080F42] bg-contain"
              style={{ backgroundImage: `url(${coach})` }}
            ></div>
            <div>
              <h1 className="text-2xl font-medium mb-4 text-[#080F42]">
                Wizard demo{" "}
              </h1>

            </div>
          </div>
        </div>
        <div className="col-span-1 px-10 pt-4">
          <h1 className="text-lg font-medium mb-4">Actions</h1>
          <div className="bg-gray-50 h-[59vh] rounded overflow-auto">
            <button
              onClick={saqueCentro}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 rounded-t flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <FaHourglassStart />
              </div>
              <span>Center Kick </span>
              <span>45'</span>
            </button>

            <button
              onClick={saquePuerta1}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <GiGoalKeeper size={20} />
              </div>
              <span>Goal Kick </span>
              <span>45'</span>{" "}
            </button>
            <button
              onClick={saqueBanda1}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiThrowingBall size={20} />
              </div>
              <span>Throw-in </span>
              <span>47'</span>{" "}
            </button>
            <button
              onClick={saqueBanda2}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiThrowingBall size={20} />
              </div>
              <span>Throw-in </span>
              <span>47'</span>{" "}
            </button>
            <button
              onClick={falta1}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <GiCardDiscard size={20} />
              </div>
              <span>Red Card </span>
              <span>49'</span>{" "}
            </button>
            <button
              onClick={cambio1}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <IoMdSwap size={20} />
              </div>
              <span>Substitution </span>
              <span>52'</span>{" "}
            </button>
            <button
              onClick={falta2}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiSoccerKick size={20} />
              </div>
              <span>Free Kick </span>
              <span>53'</span>{" "}
            </button>
            <button
              onClick={falta3}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <GiSoccerKick size={20} />
              </div>
              <span>Free Kick </span>
              <span>56'</span>{" "}
            </button>
            <button
              onClick={cambio2}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <IoMdSwap size={20} />
              </div>
              <span>Substitution </span>
              <span>60'</span>{" "}
            </button>
            <button
              onClick={saquePuerta2}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <GiGoalKeeper size={20} />
              </div>
              <span>Goal Kick </span>
              <span>62'</span>{" "}
            </button>
            <button
              onClick={corner1}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiCornerFlag size={20} />
              </div>
              <span>Corner </span>
              <span>77'</span>{" "}
            </button>
            <button
              onClick={corner2}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiCornerFlag size={20} />
              </div>
              <span>Corner </span>
              <span>74'</span>{" "}
            </button>
            <button
              onClick={tiroApuerta}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiBurningRoundShot size={20} />
              </div>
              <span>Shoot </span>
              <span>75'</span>{" "}
            </button>
            <button
              onClick={tiroApuerta2}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <GiBurningRoundShot size={20} />
              </div>
              <span>Shoot </span>
              <span>76'</span>{" "}
            </button>
            <button
              onClick={falta4}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <GiSoccerKick size={20} />
              </div>
              <span>Free Kick </span>
              <span>78'</span>{" "}
            </button>
            <button
              onClick={cambio3}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <IoMdSwap size={20} />
              </div>
              <span>Substitution </span>
              <span>80'</span>{" "}
            </button>
            <button
              onClick={falta5}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-red-500 rounded-full"></div>
                <GiSoccerKick size={20} />
              </div>
              <span>Free Kick </span>
              <span>84'</span>{" "}
            </button>
            <button
              onClick={gol}
              className="w-[100%] h-20 bg-[#080F42] text-white px-4 py-2 flex items-center justify-evenly space-x-2 border-b-2 border-gray-50"
            >
              <div className="flex gap-4 items-center">
                <div className="h-6 w-6 bg-white rounded-full"></div>
                <IoIosFootball size={20} />
              </div>
              <span>Goal </span>
              <span>90'</span>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewVideo;
