import React from "react";
import Navbar from "../Header/Navbar";
import about from "../../img/about.jpg";
import Footer from "../Footer/Footer";

export const Mission = () => {
  return (
    <div>
      <div className="flex justify-center sm:p-10 p-4">
        <div className="w-[1000px]">
          {/* Introducción a la empresa */}
          <p>
            In <strong>Wizard</strong>, we have formed a powerful team of professionals
            specialized in the different fields involved in developing the
            project and making it a success. We also have solid experience in
            the Spanish football scene and are familiar with the mechanisms of
            player transferring, international scouting, and team management,
            which consolidate our ability to bring the project to realization.
            In short, we know what needs to be done, and we have a team capable
            of doing it, in line with our company's motto: Talk the talk or walk
            the walk.
          </p>

          <div className=" flex justify-center">
            <img
              src={about}
              className="rounded-3xl py-10 max-h-[500px]"
              alt="About Wizard"
            />
          </div>

          {/* Texto adicional sobre la visión y misión */}
          <div>
            <h2 className="text-2xl font-bold mt-8">
              Uniting the World of Football Through Technology
            </h2>
            <p className="mt-4">
              Every day, thousands of football matches take place across the
              globe, from bustling city leagues to small-town competitions.
              These games are brimming with passion, talent, and unforgettable
              moments. Yet, many of them go unnoticed, hidden away due to the
              lack of accessible, high-quality recording solutions. How many
              spectacular goals, precise assists, and rising stars have been
              overlooked simply because they weren’t captured? At Wizard, we
              believe every player deserves to be seen.
            </p>

            <h2 className="text-2xl font-bold mt-8">
              More Than a Camera: A Platform for Visibility
            </h2>
            <p className="mt-4">
              Wizard isn’t just a camera—it’s a revolution in how football
              stories are shared. Our cutting-edge technology allows players,
              teams, and coaches to effortlessly record and showcase their
              matches in stunning quality. Beyond that, Wizard is a space for
              promotion, a platform where the invisible becomes visible. It’s
              where every talent—no matter where they’re from—has the chance to
              be discovered. Whether it’s an amateur team’s breakthrough season
              or a young striker’s highlight reel, Wizard ensures these moments
              don’t fade into obscurity.
            </p>

            <h2 className="text-2xl font-bold mt-8">
              A Valencian Vision for Football’s Future
            </h2>
            <p className="mt-4">
              Rooted in Valencia, Wizard draws from decades of experience in
              Spanish football—a world-renowned breeding ground for talent.
              We’re combining this expertise with innovative technology to
              transform scouting, recruitment, and game analysis. Our mission is
              to democratize access to professional tools, making it easier than
              ever for players to shine and for teams to discover the next big
              talent. Wizard is more than a product; it’s a commitment to the
              beautiful game and its future.
            </p>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
