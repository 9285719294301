import React, { useState } from "react";
import Navbar from "../Components/Header/Navbar";
import { FootballTitle } from "../Components/Football/FootballTitle";
import Footer from "../Components/Footer/Footer";
import minimap from "../img/minimap.png";
import heatmap from "../img/heatmap.png";
import detection from "../img/detection.png";
import clips from "../img/clips.png";
import VideoSlider from "../Components/Football/VideoSlider";
import { FaArrowCircleDown } from "react-icons/fa";

//@ts-ignore
import high from "../img/high.mp4";
//@ts-ignore
import low from "../img/low.mp4";

const imageDetails = {
  minimap: {
    title: "MINIMAP",
    description:
      "The minimap feature provides an overview of the entire field, displaying player positions in real-time. This helps in analyzing team formations and player movements throughout the game.",
  },
  heatmap: {
    title: "HEATMAP",
    description:
      "The heatmap feature highlights the areas of the field where the most activity occurs. This visual representation helps in identifying key zones and player tendencies.",
  },
  detection: {
    title: "DETECTION",
    description:
      "The detection feature uses advanced AI to track player movements and actions. It helps in detailed analysis of individual player performance and team strategies.",
  },
};

type ImageKey = keyof typeof imageDetails;

export const Football = () => {
  const [currentImage, setCurrentImage] = useState<ImageKey>("minimap");

  const handleImageChange = (imageKey: ImageKey) => {
    setCurrentImage(imageKey);
  };

  return (
    <div>
      <Navbar />
      <FootballTitle />
      <div className="flex justify-center p-10">
        <div className="w-[1200px]">
          <div className="sm:grid grid-cols-2 pb-10">
            <div>
              <img
                src={clips}
                className="rounded-3xl shadow-2xl max-h-[500px]"
                alt="Clips preview"
              />
            </div>
            <div>
              <h2 className="font-bold text-3xl sm:pl-20 sm:text-left text-center sm:pt-0 pt-20 text-[#080F42]">
                HIGHLIGHTS
              </h2>
              <p className="sm:pl-20 text-left">
                The Wizard Platform offers seamless access to your extensive
                match library, allowing you to view all recorded games captured
                by your Wizard Device. With an intuitive interface, you can
                easily browse through your collection, search for specific
                matches, and watch recordings at your convenience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center p-10 bg-[#EFEFEF]">
        <div className="w-[1200px]">
          <div className="sm:grid grid-cols-2 pb-10">
            <div>
              <h2 className="font-bold text-3xl sm:text-left text-center sm:pt-4 pt-20 text-[#080F42]">
                {imageDetails[currentImage].title}
              </h2>
              <p>{imageDetails[currentImage].description}</p>
              <div className="sm:flex mb-8 sm:mb-0 justify-between mt-20 gap-4">
                {(["heatmap", "detection", "minimap"] as ImageKey[]).map(
                  (key) => (
                    <button
                      key={key}
                      onClick={() => handleImageChange(key)}
                      className={`border-2 rounded-3xl p-2 w-40 ${
                        currentImage === key
                          ? "bg-[#080F42] text-white"
                          : "bg-white text-black"
                      }`}
                    >
                      {imageDetails[key].title}
                    </button>
                  )
                )}
              </div>
            </div>
            <div>
              <img
                src={
                  currentImage === "minimap"
                    ? minimap
                    : currentImage === "heatmap"
                    ? heatmap
                    : detection
                }
                className="rounded-3xl max-h-[500px]"
                alt={imageDetails[currentImage].title}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-[1300px] p-10">
          <div className="flex justify-between pb-5">
            <h2 className="font-bold text-3xl text-[#080F42]">Before AI</h2>
            <h2 className="font-bold text-3xl text-[#080F42] flex items-center gap-2"> <FaArrowCircleDown size={20} /> Slide me! <FaArrowCircleDown size={20} /></h2>

            <h2 className="font-bold text-3xl text-[#080F42] ">After AI 
            </h2>
          </div>
          <VideoSlider videoLeft="https://img.wizardfootball.com/stream_videos/demo_high.mp4" videoRight="https://img.wizardfootball.com/stream_videos/demo_low.mp4" />
          </div>
      </div>
      <Footer />
    </div>
  );
};
