import React from "react";
import Navbar from "../Components/Header/Navbar";
import Footer from "../Components/Footer/Footer";
//@ts-ignore
import privacy from "../legal/PrivacyPolicy.pdf";
//@ts-ignore
import terms from "../legal/TERMINOSYCONDICIONES.pdf";

export const Legal = () => {
  return (
    <div>
      <div className="bg-black min-h-screen text-white ">
        <Navbar />
        <div className=" flex justify-center">
          <div className="sm:pt-80 pt-40 px-20 lg:w-[1200px]">
            <h1 className="text-3xl font-bold text-center mb-10">
              Legal Information
            </h1>

            {/* Privacy Policy Section */}
            <section className="mb-10">
              <h2 className="text-2xl font-semibold mb-4">Privacy Policy</h2>
              <p className="text-gray-300 mb-6">
                At Wizard, we are committed to protecting your privacy and
                safeguarding any personal information you share with us. Our
                privacy policy outlines how we collect, use, and protect your
                data in compliance with applicable laws and regulations. We
                encourage you to review our policy to understand your rights and
                our responsibilities.
              </p>
              <a
                href={privacy}
                download="PrivacyPolicy.pdf"
                className="inline-block bg-white text-black font-medium px-6 py-3 rounded-lg hover:bg-gray-300 transition "
              >
                Download Privacy Policy
              </a>
            </section>

            {/* Terms and Conditions Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                Terms & Conditions
              </h2>
              <p className="text-gray-300 mb-6">
                Our Terms and Conditions govern the use of our services and
                products, outlining your obligations and our commitments. By
                accessing or using our platform, you agree to these terms, which
                are designed to ensure a fair and transparent experience for all
                users. Please read them carefully.
              </p>
              <a
                href={terms}
                download="TermsAndConditions.pdf"
                className="inline-block bg-white text-black font-medium px-6 py-3 rounded-lg hover:bg-gray-300 transition"
              >
                Download Terms & Conditions
              </a>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
