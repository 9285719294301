import React from "react";
import background from "../../img/Background-header.jpg";
import device from "../../img/Renders_Wizard/render1.3.png";
import { useNavigate } from "react-router-dom";
import "./title.css";

export const Title = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        width: "100%",
        display: "flex", // Esto hace que el contenedor sea flexible
        flexDirection: "column", // Organiza los elementos en columna
        justifyContent: "space-between", // Asegura que el gradiente quede al final
      }}
    >
      <div className="flex flex-col justify-center items-center p-10 pt-36 sm:pt-[200px]">
        <div className="md:flex justify-center gap-10 items-center z-10 w-full">
          <div className="md:w-[40%] pt-20 sm:pt-0">
            <img src={device} className="fade-in w-full" alt="" />
            <div className="w-full mt-10 flex justify-center text-center text-white text-2xl font-bold">
              <h2>Record football matches using cutting-edge AI technology.</h2>
            </div>
            <div className="w-full flex mt-2 justify-center text-center text-white text-md">
              <p>
                Experience unmatched clarity and precision as our AI-driven
                system captures every thrilling moment on the field. Elevate
                your game analysis!
              </p>
            </div>
            <div className="flex justify-center gap-10 font-bold text-white">
              <button
                onClick={() =>
                  (window.location.href = "https://wizardfootball.com")
                }
                className="mt-8 border rounded-3xl p-2 w-40 bg-[#be95ff] hover:bg-transparent"
              >
                Join Wizard
              </button>
              <a href="https://www.wizardfootball.com/checkout">
                <button className="mt-8 border rounded-3xl p-2 w-40 border-[#be95ff] hover:bg-[#be95ff] hover:border-white">
                  Buy Now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-t from-black to-transparent w-full h-[300px]"></div>
    </div>
  );
};
