import React, { useState, useRef } from "react";

interface VideoSliderProps {
  videoLeft: string;
  videoRight: string;
}

const VideoSlider: React.FC<VideoSliderProps> = ({ videoLeft, videoRight }) => {
  const [sliderPosition, setSliderPosition] = useState(50); // Barra al 50%
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleSliderMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const newSliderPosition = (offsetX / rect.width) * 100; // Convierte a porcentaje
      setSliderPosition(Math.min(Math.max(newSliderPosition, 0), 100)); // Restringir entre 0% y 100%
    }
  };

  return (
    <div
      className="relative w-full h-[500px] overflow-hidden"
      ref={containerRef}
      onMouseMove={(e) => {
        if (e.buttons === 1) {
          handleSliderMove(e); // Mueve solo si el mouse está presionado
        }
      }}
      onMouseDown={(e) => handleSliderMove(e)} // Mueve la barra cuando se hace clic
    >
      {/* Contenedor del video izquierdo */}
      <div className="absolute top-0 left-0 h-full" style={{ width: "100%" }}>
        <video
          src={videoLeft}
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
        />
      </div>

      {/* Contenedor del video derecho con clip-path */}
      <div
        className="absolute top-0 left-0 h-full"
        style={{
          width: "100%",
          clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0% 100%)`,
        }}
      >
        <video
          src={videoRight}
          className="w-full h-full object-cover"
          autoPlay
          loop
          muted
        />
      </div>

      {/* Barra deslizante */}
      <div
        className="absolute top-0 left-0 h-full z-10"
        style={{ left: `${sliderPosition}%` }}
      >
        <div
          className="w-4 h-full bg-[#080F42] cursor-ew-resize"
          style={{ position: "absolute", transform: "translateX(-50%)" }}
        >
          <div className="h-full w-2 border-r border-gray-100 border-l ml-[2px] opacity-50"></div>
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
